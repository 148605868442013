<template>
  <div class="camera-wrapper">
    <div class="overlay"></div>
    <video v-show="loaded" id="video" ref="barcode" class="cameraCustom"></video>
  </div>
</template>

<script>
export default {
  name: 'ScannerCamera',
  components:{
  },
  data() {
    return {
      loaded: false
    }
  },
  mounted() {
    this.$refs.barcode.onloadeddata = () => {
      this.loaded = true
    }
  }
}
</script>

<style scoped>
.camera-wrapper{
  height: 100%;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  overflow: hidden;
}
.camera-wrapper video{
  height: 100%;
}
</style>