import { BrowserMultiFormatReader, NotFoundException } from '@zxing/library';
const codeReader = new BrowserMultiFormatReader();
const constraints = {
    audio: false,
    video: {
      width: { min: 640, ideal: 1280, max: 1920 },
      height: { min: 480, ideal: 720, max: 1080 },
      facingMode: { ideal: 'environment' },
      aspectRatio: 16/9
    },
  };
export async function getCameras(){
    let listingVideoInput = await navigator.mediaDevices.getUserMedia(constraints)
    console.log(listingVideoInput)

    const track = listingVideoInput.getVideoTracks()[0];
    try{
        track.applyConstraints({
            advanced: [{
              focusMode: "auto"
            }]
        });

    }catch(e){
        console.error(e)
    }

    let a = await codeReader.listVideoInputDevices()
    console.log(a)

    let b = await navigator.mediaDevices.enumerateDevices()
    console.log(b)

}
export function start(callback, orientation = 'portrait'){
    codeReader.timeBetweenScansMillis = 2000
    // let selectedDeviceId;
    let selectedDeviceId;
    codeReader.listVideoInputDevices()
        .then((videoInputDevices) => {
            console.log(videoInputDevices);
            let backCameraDevices = videoInputDevices.filter(device => /back|rear|environment/gi.test(device.label))
            let lastDevice = backCameraDevices.length - 1
            selectedDeviceId = lastDevice >= 0 ? backCameraDevices[lastDevice].deviceId : undefined
            navigator.mediaDevices.getUserMedia({ video: { deviceId: { exact: selectedDeviceId } } })
            .then(stream => {
                try{
                    const track = stream.getVideoTracks()[0];
                    track.applyConstraints({
                        advanced: [{
                            focusMode: "auto"
                        }]
                    });
                }catch(e){
                    console.error(e)
                }
            })

            codeReader.decodeFromVideoDevice(selectedDeviceId, 'video', (result, err) => {
                if (result) {
                    console.log(result)
                    callback({
                        codeResult: {
                            code: result.text
                        }
                    })
                }
                if (err && !(err instanceof NotFoundException)) {
                console.error(err)
                }
            })
    })
}

export function reset(){
    codeReader.reset()
}

function getType(code){
    switch (code){
        case 0 : return "AZTEC"
        case 1 : return "CODABAR"
        case 2 : return "CODE_39"
        case 3 : return "CODE_93"
        case 4 : return "CODE_128"
        case 5 : return "DATA_MATRIX"
        case 6 : return "EAN_8"
        case 7 : return "EAN_13"
        case 8 : return "ITF"
        case 9 : return "MAXICODE"
        case 10 : return "PDF_417"
        case 11 : return "QR_CODE"
        case 12 : return "RSS_14"
        case 13 : return "RSS_EXPANDED"
        case 14 : return "UPC_A"
        case 15 : return "UPC_E"
        case 16 : return "UPC_EAN_EXTENSION"
        default : return "UNKNOWN"
    }
}
